// This file should not be referenced directly.  This file is aliased in apps/website/vite.config.ts to replace the
// server logger in the client build.

// This is named with `_client` instead of `.client` to prevent issues in development SSR.  We cannot identify that it
// is an SSR build in vite.config.ts, so it tries to alias the file.  If the file is client-only, it won't properly
// load.

/* eslint-disable no-console -- browser logging will use `console` */
export const logger = {
  debug: console.debug,
  info: console.info,
  warn: console.warn,
  error: console.error,
}
/* eslint-enable no-console -- browser logging will use `console` */
