import { type BugsnagStatic, default as internalBugsnag } from '@bugsnag/js'
import { context, trace } from '@opentelemetry/api'

import { clientEnv } from './env.js'
import { logger } from './logger/logger.js'

// For some reason, the typescript typing isn't getting pulled in on this import...
const Bugsnag = internalBugsnag as unknown as BugsnagStatic

export const startErrorReporter = () => {
  if (!clientEnv.BUGSNAG_API_KEY) {
    logger.info('BUGSNAG_API_KEY not set, skipping error reporting')
    return
  }

  Bugsnag.start({
    apiKey: clientEnv.BUGSNAG_API_KEY,
    releaseStage: clientEnv.HABITAT,
    appVersion: clientEnv.APP_VERSION,
    plugins: [],
    onError: (event) => {
      const currentSpanContext = trace.getSpan(context.active())?.spanContext()
      if (currentSpanContext) {
        event.addMetadata('Trace', {
          traceId: currentSpanContext.traceId,
          spanId: currentSpanContext.spanId,
        })
      }
    },
  })
}

type NotifyParams = Parameters<typeof Bugsnag.notify>

export const captureError = (
  error: Error,
  options?: {
    skipSnag?: boolean
    onError?: NotifyParams[1]
    postReportCallback?: NotifyParams[2]
  },
) => {
  if (clientEnv.BUGSNAG_API_KEY) {
    if (!(error instanceof Error)) {
      logger.error('The following error was not an instance of Error, converting to string')
      error = new Error(String(error))
    }
    if (!options?.skipSnag) {
      Bugsnag.notify(error, options?.onError, options?.postReportCallback)
    }
  }
  logger.error(String(error))
}
