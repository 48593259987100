import { isServer } from './is-server.js'

declare global {
  interface Window {
    env: {
      [key: string]: string | undefined
    }
  }
}

// eslint-disable-next-line nav/no-process-env -- only allowed in this file
const environment = (isServer ? process.env : window.env) ?? {}

const {
  NODE_ENV,
  PORT,
  SINGER_URL,
  SESSIONMGMT_SESSION_COOKIE_NAME,
  SESSIONMGMT_DEVICE_COOKIE_NAME,
  SESSIONMGMT_COOKIE_DOMAIN,
  SESSIONMGMT_SESSION_HEADER_NAME,
  NAV_CMS_URL,
  NAV_CMS_PUBLIC_URL,
  APP_VERSION,
  BUGSNAG_API_KEY,
  HABITAT,
  NAV_WEBSITE_SERVER_URL,
  PREVIEW_SECRET,
  REVALIDATION_KEY,
  CAPTCHA_SITE_KEY,
  CAPTCHA_SECRET_KEY,
  LAUNCH_DARKLY_KEY,
  LAUNCH_DARKLY_CLIENT_ID,
  SHARED_COOKIE_DOMAIN,
  SHARED_COOKIE_SECURE,
  REDIS_URL,
  DISABLE_METRICS,
  METRICS_PORT = '2112',
  TEALIUM_SCRIPT_URL,
} = environment

const requireEnv = <T extends Record<string, string | undefined>>(env: T) => env as Record<keyof T, string>

const requiredEnv = requireEnv({
  SINGER_URL,
  SESSIONMGMT_SESSION_COOKIE_NAME,
  SESSIONMGMT_DEVICE_COOKIE_NAME,
  SESSIONMGMT_COOKIE_DOMAIN,
  SESSIONMGMT_SESSION_HEADER_NAME,
  NAV_CMS_URL,
  NAV_CMS_PUBLIC_URL,
  HABITAT,
  NAV_WEBSITE_SERVER_URL,
  PREVIEW_SECRET,
  REVALIDATION_KEY,
  METRICS_PORT,
  TEALIUM_SCRIPT_URL,
} as const)

export const env = {
  ...requiredEnv,
  NODE_ENV,
  PORT,
  APP_VERSION,
  BUGSNAG_API_KEY,
  CAPTCHA_SECRET_KEY,
  LAUNCH_DARKLY_KEY,
  SHARED_COOKIE_DOMAIN,
  SHARED_COOKIE_SECURE,
  REDIS_URL,
  DISABLE_METRICS,
  LAUNCH_DARKLY_CLIENT_ID,
} as const

export const clientEnv = {
  NODE_ENV,
  APP_VERSION,
  BUGSNAG_API_KEY,
  NAV_CMS_URL: requiredEnv.NAV_CMS_URL,
  NAV_CMS_PUBLIC_URL: requiredEnv.NAV_CMS_PUBLIC_URL,
  HABITAT: requiredEnv.HABITAT,
  NAV_WEBSITE_SERVER_URL: requiredEnv.NAV_WEBSITE_SERVER_URL,
  TEALIUM_SCRIPT_URL: requiredEnv.TEALIUM_SCRIPT_URL,
  LAUNCH_DARKLY_CLIENT_ID,
  CAPTCHA_SITE_KEY,
} as const

export const getMissingEnv = () => {
  return Object.entries(requiredEnv)
    .filter(([_key, value]) => !value)
    .map(([key]) => key)
}
