// isomorphic logger that uses the server logger by default.  The client logger is aliased to replace the server logger for the browser in the vite config

import { logger as serverLogger } from './_internal_logger.server.js'

/* eslint-disable @typescript-eslint/no-explicit-any -- logging is quite permissive */

interface LogMethod {
  (message: string, ...meta: any[]): void
  (message: any): void
  (infoObject: object): void
}

type IsomorphicLogger = {
  info: LogMethod
  error: LogMethod
  warn: LogMethod
  debug: LogMethod
}
/* eslint-enable @typescript-eslint/no-explicit-any -- logging is quite permissive */

export const logger = serverLogger as IsomorphicLogger
